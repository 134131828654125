<template>
  <basket-list lang="RU"/>
</template>

<script setup>
import BasketList from "@/views/year/baskets/BasketList.vue";

export default {
  components: {
    BasketList,
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">

</style>